html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
template,
[hidden] {
  display: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: bold;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: 25px;
}
[dir='rtl'] .slick-prev {
  right: 25px;
  left: auto;
}
.slick-prev:before {
  content: '←';
}
[dir='rtl'] .slick-prev:before {
  content: '→';
}
.slick-next {
  right: 25px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: 25px;
}
.slick-next:before {
  content: '→';
}
[dir='rtl'] .slick-next:before {
  content: '←';
}
.slick-dotted.slick-slider {
  margin-bottom: 2.8rem;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  height: 25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 25px;
  margin: 0 10px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  display: block;
  width: 12px;
  height: 25px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  content: ' ';
  background-color: #f05509;
  text-align: center;
  border-radius: 6px;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
}
.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.left {
  float: left;
}
.right {
  float: right;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
html {
  box-sizing: border-box;
}
html * {
  box-sizing: inherit;
  cursor: inherit;
}
a,
button {
  color: #387896;
  text-decoration: none;
}
a:hover,
button:hover,
a:focus,
button:focus,
a:visited,
button:visited {
  text-decoration: underline;
}
button {
  background: transparent;
  border: 0;
}
pre {
  background: #f0f0f0;
  margin: 1rem 0;
  border-radius: 2px;
}
blockquote {
  border-left: 10px solid #eee;
  margin: 0;
  padding: 0 2rem;
}
img,
iframe {
  vertical-align: bottom;
  max-width: 100%;
}
td {
  text-align: left;
  vertical-align: top;
}
html {
  font-size: 22px;
  line-height: 1.4;
}
@media screen and (max-width: 1600px) {
  html {
    font-size: 20px;
  }
}
@media screen and (max-width: 1280px) {
  html {
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) {
  html {
    font-size: 17px;
  }
}
body {
  font-family: "Lato", sans-serif;
  font-size: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 1.4rem;
  line-height: 1;
  font-weight: normal;
  font-style: italic;
}
h1,
h2 {
  margin-bottom: 2.8rem;
  font-size: 2.5rem;
  font-weight: lighter;
  color: #f05509;
}
@media screen and (max-width: 640px) {
  h1,
  h2 {
    font-size: 1.7rem;
  }
}
h3 {
  font-size: 1.7rem;
  font-weight: lighter;
}
h4,
h5,
h6 {
  font-size: 1.3rem;
}
p,
ul,
ol,
table {
  margin: 0 0 1.4rem;
}
header {
  margin: 0 0 1.4rem;
}
.frontpage header.page-section {
  padding: 0;
}
header .logo {
  margin: 1.4rem auto;
  width: 90%;
  max-width: 500px;
}
.main-nav {
  position: fixed;
  top: 1.4rem;
  left: 1.4rem;
  width: 2.8rem;
  height: 2.8rem;
  text-align: left;
  background: #387896;
  z-index: 100;
}
.main-nav::before,
.main-nav::after {
  position: absolute;
  content: "";
  display: block;
  left: 14.285714285714285%;
  right: 14.285714285714285%;
  height: 12.5%;
  background: #fff;
}
.main-nav::before {
  top: 14.285714285714285%;
}
.main-nav::after {
  bottom: 14.285714285714285%;
}
.main-nav ul {
  position: relative;
  list-style: none;
  width: 71.42857142857143%;
  height: 12.5%;
  padding: 0;
  margin: auto;
  margin-top: 43.75%;
  background: #fff;
  pointer-events: none;
}
.main-nav li {
  opacity: 0;
}
.main-nav.hover ul {
  pointer-events: all;
}
.main-nav:hover {
  width: auto;
  height: auto;
  max-height: 88vh;
  overflow-y: auto;
  background-color: transparent;
}
.main-nav:hover::before,
.main-nav:hover::after {
  display: none;
}
.main-nav:hover ul {
  height: auto;
  width: auto;
  margin-top: 0;
  background-color: transparent;
}
.main-nav:hover li {
  padding: 0.7rem;
  background-color: #387896;
  opacity: 1;
  transition: opacity 0.4s;
}
.main-nav:hover li a {
  display: block;
  color: #fff;
  font-size: 1.3rem;
  text-transform: uppercase;
}
.main-nav:hover li + li {
  border-top: 3px solid #fff;
}
.main-nav:hover li ul {
  background: transparent;
  padding: 0 1.4rem;
}
.main-nav:hover li ul li {
  padding: 0;
  border: none;
}
.main-nav:hover li ul li a {
  text-transform: none;
  font-weight: lighter;
  font-style: italic;
}
.page-section {
  padding: 0 2.8rem;
  padding-bottom: 1.4rem;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .page-section {
    padding: 0 1.4rem;
  }
}
.page-section.page-content {
  padding-top: 0;
}
header.page-section {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}
header.page-section h1:first-child,
header.page-section h2:first-child {
  margin-top: 2.8rem;
}
.banner {
  padding: 0;
  height: 100vh;
  overflow: hidden;
}
.banner h1,
.banner h2 {
  margin-top: 75vh;
}
.banner .logo {
  position: absolute;
  top: 0;
  bottom: 2.5rem;
  left: 0;
  right: 0;
  width: auto;
  max-width: 70%;
  max-height: 15em;
  margin: auto;
}
.full-size {
  padding: 0;
  margin-bottom: 1.4rem;
}
.colored {
  background: #349793;
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
}
.colored:not(:last-child) {
  margin-bottom: 1.4rem;
}
.colored p:last-child {
  margin: 0;
}
.colored.full-size {
  margin-left: -2.8rem;
  margin-right: -2.8rem;
  margin-bottom: 2.8rem;
}
.colored.full-size .page-content {
  padding: 0 2.8rem;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .colored.full-size {
    margin-left: -1.4rem !important;
    margin-right: -1.4rem !important;
  }
}
@media screen and (max-width: 640px) {
  .colored {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }
}
.colored * {
  color: #fff;
  text-shadow: 0 3px 3px #387896;
}
.page-content {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto 1.4rem;
}
.page-content table {
  width: 100%;
  border-collapse: collapse;
}
.page-content td {
  padding: 0.35rem 0.7rem 0.35rem 0;
}
.page-content tr:not(:first-child) td {
  border-top: 1px solid #349793;
}
.text-content {
  text-align: left;
}
@lost gutter 50px;
@lost rounder 99.8;
.tile-list,
.media-list,
.project-list,
.image-list {
  list-style: none;
  padding-left: 0;
  lost-center: 1024px;
}
@supports (display: flex) {
  .tile-list,
  .media-list,
  .project-list,
  .image-list {
    lost-center: 1024px flex;
  }
}
.tab-selection {
  list-style: none;
  padding-left: 0;
}
.item-title,
.item-image {
  width: 100%;
  margin: 0 0 0.9rem;
}
.item-title img,
.item-image img {
  width: 100%;
}
.feature-item {
  position: relative;
  lost-column: 1/2 2 60px;
  margin-bottom: 1.4rem;
}
@media screen and (max-width: 820px) {
  .feature-item {
    lost-column: 1 0 0;
  }
}
.feature-item .item-image {
  position: relative;
  overflow: hidden;
  box-shadow: 0 20px 20px -10px rgba(56,120,150,0.4);
  border-radius: 20px;
}
.feature-item .item-image figcaption {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  text-shadow: 0 2px 2px #387896;
  background: rgba(52,151,147,0.94);
  opacity: 0;
  transition: opacity 0.4s;
}
.feature-item .item-image figcaption p {
  margin: 0;
}
.feature-item .item-image:hover figcaption {
  opacity: 1;
}
.feature-item .item-description {
  padding: 1.4rem;
  border-radius: 20px;
}
.feature-item .item-title {
  color: #f05509;
}
.person-item {
  lost-column: 1/3;
  margin-bottom: 1.4rem;
}
@media screen and (max-width: 820px) {
  .person-item {
    lost-column: 1/2;
  }
}
@media screen and (max-width: 480px) {
  .person-item {
    lost-column: 1 0 0;
  }
}
.person-item .item-title {
  color: #f05509;
}
.person-item .item-image {
  position: relative;
  overflow: hidden;
  box-shadow: 0 20px 20px -10px rgba(56,120,150,0.4);
  border-radius: 10px;
}
.person-item .item-image figcaption {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  text-shadow: 0 2px 2px #387896;
  background: rgba(52,151,147,0.94);
  opacity: 0;
  transition: opacity 0.4s;
}
.person-item .item-image figcaption p {
  margin: 0;
}
.person-item .item-image:hover figcaption {
  opacity: 1;
}
.person-item .item-description {
  padding: 0.7rem;
  border-radius: 10px;
}
.image-item {
  display: none;
  margin-bottom: 2.8rem;
  lost-masonry-column: 1/4;
}
.image-item.active {
  display: block;
}
@media screen and (max-width: 820px) {
  .image-item {
    lost-masonry-column: 1/3;
  }
}
@media screen and (max-width: 640px) {
  .image-item {
    lost-masonry-column: 1/2;
  }
}
@media screen and (max-width: 480px) {
  .image-item {
    lost-column: 1 0 0;
    margin: 0 0 1.4rem !important;
    padding: 1.4rem;
  }
}
.image-item a {
  display: block;
  border: 0.35rem solid #fff;
  background-color: #fff;
  box-shadow: 0 20px 20px -10px #387896;
  transition: transform 0.4s;
}
.image-item a:hover {
  transform: scale(0.96);
}
@supports (display: flex) {
  .image-item.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.project-item {
  display: block;
  border: 0.35rem solid #fff;
  background-color: #fff;
  box-shadow: 0 20px 20px -10px #387896;
  transition: transform 0.4s;
  display: none;
  position: relative;
  margin-bottom: 2.8rem;
  lost-masonry-column: 1/3;
}
.project-item:hover {
  transform: scale(0.96);
}
.project-item.active {
  display: block;
}
@media screen and (max-width: 820px) {
  .project-item {
    lost-masonry-column: 1/2;
  }
}
@media screen and (max-width: 480px) {
  .project-item {
    lost-column: 1 0 0;
    margin: 0 0 2.8rem;
  }
}
.project-item * {
  text-shadow: none;
}
@supports (display: flex) {
  .project-item a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    height: 100%;
  }
}
.project-item .item-title {
  color: #f05509;
  margin-bottom: 0.35rem;
  font-style: italic;
}
.project-item .item-year {
  color: #222;
  margin-bottom: 0.35rem;
  font-style: italic;
}
.project-image {
  lost-column: 1/4 4;
  margin-bottom: 2.8rem;
}
@media screen and (max-width: 820px) {
  .project-image {
    lost-column: 1/3 3;
  }
}
@media screen and (max-width: 640px) {
  .project-image {
    lost-column: 1/2 2;
  }
}
@media screen and (max-width: 480px) {
  .project-image {
    lost-column: 1 0 0;
    margin: 0 0 2.8rem;
  }
}
.project-image a {
  display: block;
  border: 0.35rem solid #fff;
  background-color: #fff;
  box-shadow: 0 20px 20px -10px #387896;
  transition: transform 0.4s;
}
.project-image a:hover {
  transform: scale(0.96);
}
@supports (display: flex) {
  .project-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.column-container {
  lost-center: 1024px;
}
.column-container .column {
  lost-column: 1/2;
  margin-bottom: 1.4rem;
  text-align: left;
}
@media screen and (max-width: 820px) {
  .column-container .column {
    lost-column: 1;
  }
}
.tab-selection li {
  display: inline-block;
  padding: 0.7rem;
  font-size: 1.3em;
  text-shadow: 0 3px 3px #387896;
}
.tab-selection li .selected {
  text-decoration: underline;
}
.media-wrapper {
  margin-bottom: 2.8rem;
  position: relative;
  padding-bottom: 56.25% /* Default for 1600x900 videos 16:9 ratio*/;
  padding-top: 0px;
  height: 0;
  overflow: hidden;
}
.media-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
