.visuallyhidden
  position: absolute
  width: 1px
  height: 1px
  margin: -1px
  padding: 0
  border: 0
  overflow: hidden
  clip: rect(0 0 0 0)


.left
    float: left

.right
    float: right

.text-center
    text-align: center

.text-right
    text-align: right

.text-left
    text-align: left

box()
  display: block
  border: ($margin-sm / 2) solid white
  background-color: white
  box-shadow: 0 20px 20px -10px $color-interaction
  transition: transform $hover-animation-duration

  &:hover
    transform: scale($hover-scale-factor)


activate()
  display: none

  &.active
    display: block


clearlist()
  list-style: none
  padding-left: 0


interactive-figure()
  position: relative
  overflow: hidden
  box-shadow: 0 20px 20px -10px rgba($color-interaction, 0.4)

  figcaption
    position: absolute
    display: flex
    flex-direction: column
    justify-content: center
    top: 0
    left: 0
    right: 0
    bottom: 0
    color white
    text-shadow: 0 2px 2px $color-interaction
    background: rgba($color-deco, 0.94)
    opacity: 0
    transition: opacity $hover-animation-duration

    p
      margin: 0

  &:hover figcaption
    opacity: 1
