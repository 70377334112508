html
  font-size: $font-size-big
  line-height: $line-height

  @media screen and (max-width: $screen-xxl)
    font-size: $font-size-base

  @media screen and (max-width: $screen-xl)
    font-size: $font-size-medium

  @media screen and (max-width: $screen-lg)
    font-size: $font-size-small


body
  font-family: $font-family
  font-size: inherit


h1, h2, h3, h4, h5, h6
  margin: 0
  margin-bottom: $margin
  line-height: $line-height-headings
  font-weight: normal
  font-style: italic


h1, h2
  margin-bottom: $margin * 2
  font-size: ($font-scale-header)rem
  font-weight: lighter
  color: $color-highlight

  @media screen and (max-width: $screen-sm)
    font-size: ($font-scale-large)rem

h3
  font-size: ($font-scale-large)rem
  font-weight: lighter

h4, h5, h6
  font-size: ($font-scale-big)rem

p, ul, ol, table
  margin: 0 0 $margin
