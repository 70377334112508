/* Core Stuff */
html
  box-sizing: border-box

  *
    box-sizing: inherit
    cursor: inherit


a
button
  color: $color-interaction
  text-decoration: none

  &:hover
  &:focus
  &:visited
    text-decoration: underline


button
  background: transparent
  border: 0


pre
  background: #F0F0F0
  margin: 1rem 0
  border-radius: 2px


blockquote
  border-left: 10px solid #eee
  margin: 0
  padding: 0 2rem


img,
iframe
  vertical-align: bottom
  max-width: 100%


td
  text-align: left
  vertical-align: top
