// ===== Header =====

header
  margin: 0 0 $margin

  .frontpage &.page-section
    padding 0

  .logo
    margin: $margin auto
    width: 90%
    max-width: 500px


// ===== Navigation menu =====

.main-nav
  position: fixed
  top: $margin
  left: $margin
  width: $margin * 2
  height: $margin * 2
  text-align: left
  background: $color-interaction
  z-index: $z-index-nav

  &::before
  &::after
    position: absolute
    content: ""
    display: block
    left: $one-seventh
    right: $one-seventh
    height: $one-eighth
    background: white

  &::before
    top: $one-seventh

  &::after
    bottom: $one-seventh

  ul
    position: relative
    list-style: none
    width: $five-seventh
    height: $one-eighth
    padding: 0
    margin: auto
    margin-top: $three-seventh
    background: white
    pointer-events: none

  li
    opacity: 0


  &.hover
    ul
      pointer-events: all


  &:hover
    width: auto
    height: auto
    max-height: 88vh
    overflow-y: auto
    background-color: transparent

    &::before
    &::after
      display: none

    ul
      height: auto
      width: auto
      margin-top: 0
      background-color: transparent

    li
      padding: $margin-sm
      background-color: $color-interaction
      opacity: 1
      transition: opacity $hover-animation-duration

      a
        display: block
        color: white
        font-size: ($font-scale-big)rem
        text-transform: uppercase

      & + li
        border-top: 3px solid white

      ul
        background: transparent
        padding: 0 $margin

        li
          padding: 0
          border: none

          a
            text-transform: none
            font-weight: lighter
            font-style: italic


// ===== Content sections =====

.page-section
  padding: 0 $margin * 2
  padding-bottom: $margin
  text-align: center

  @media screen and (max-width: $screen-sm)
    padding: 0 $margin


  &.page-content
    padding-top: 0

header.page-section
  padding-top: 0
  padding-bottom: 0
  margin: 0

  h1:first-child
  h2:first-child
    margin-top: $margin * 2

.banner
  padding: 0
  height: 100vh
  overflow: hidden

  h1, h2
    margin-top: 75vh

  .logo
    position: absolute
    top: 0
    bottom: ($font-scale-header)rem
    left: 0
    right: 0
    width: auto
    max-width: 70%
    max-height: 15em
    margin: auto


.full-size
  padding: 0
  margin-bottom: $margin


.colored
  background: $color-deco
  padding-top: $margin * 2
  padding-bottom: $margin * 2

  &:not(:last-child)
    margin-bottom: $margin

  p:last-child
    margin: 0

  &.full-size
    margin-left: -($margin * 2)
    margin-right: -($margin * 2)
    margin-bottom: $margin * 2

    .page-content
      padding: 0 ($margin * 2)
      margin: 0 auto

    @media screen and (max-width: $screen-sm)
      margin-left: -($margin) !important
      margin-right: -($margin) !important

  @media screen and (max-width: $screen-sm)
    padding-top: $margin
    padding-bottom: $margin

  *
    color: white
    text-shadow: 0 3px 3px $color-interaction


.page-content
  width: 100%
  max-width: $screen-lg
  margin: 0 auto $margin

  table
    width: 100%
    border-collapse: collapse
  td
    padding: ($margin-sm / 2) $margin-sm ($margin-sm / 2) 0

  tr:not(:first-child) td
    border-top: 1px solid $color-deco

.text-content
  text-align: left

// ===== Tile lists =====

@lost gutter 50px
@lost rounder 99.8

.tile-list
.media-list
.project-list
.image-list
  clearlist()
  lost-center: $screen-lg

  @supports (display: flex)
    lost-center: $screen-lg flex


.tab-selection
  clearlist()


.item-title
.item-image
  width: 100%
  margin: 0 0 0.9rem

  img
    width: 100%


.feature-item
  position: relative
  lost-column: 1/2 2 60px
  margin-bottom: $margin

  @media screen and (max-width: $screen-md)
    lost-column: 1 0 0

  .item-image
    interactive-figure()
    border-radius: 20px

  .item-description
    padding: $margin
    border-radius: 20px

  .item-title
    color: $color-highlight


.person-item
  lost-column: 1/3
  margin-bottom: $margin

  @media screen and (max-width: $screen-md)
    lost-column: 1/2

  @media screen and (max-width: $screen-xs)
    lost-column: 1 0 0

  .item-title
    color: $color-highlight

  .item-image
    interactive-figure()
    border-radius: 10px

  .item-description
    padding: $margin-sm
    border-radius: 10px


.image-item
  activate()
  margin-bottom: $margin * 2

  lost-masonry-column: 1/4

  @media screen and (max-width: $screen-md)
    lost-masonry-column: 1/3

  @media screen and (max-width: $screen-sm)
    lost-masonry-column: 1/2

  @media screen and (max-width: $screen-xs)
    lost-column: 1 0 0
    margin: 0 0 ($margin) !important
    padding: $margin

  a
    box()

  &.active
    @supports (display: flex)
      display: flex
      flex-direction: column
      justify-content: center


.project-item
  box()
  activate()
  position: relative
  margin-bottom: $margin * 2

  lost-masonry-column: 1/3

  @media screen and (max-width: $screen-md)
    lost-masonry-column: 1/2

  @media screen and (max-width: $screen-xs)
    lost-column: 1 0 0
    margin: 0 0 ($margin * 2)

  *
    text-shadow: none

  a
    @supports (display: flex)
      display: flex
      flex-direction: column
      justify-content: space-between
      align-self: stretch
      height: 100%

  .item-title
    color: $color-highlight
    margin-bottom: ($margin-sm / 2)
    font-style: italic

  .item-year
    color: $color-font
    margin-bottom: ($margin-sm / 2)
    font-style: italic


.project-image
  lost-column: 1/4 4
  margin-bottom: $margin * 2

  @media screen and (max-width: $screen-md)
    lost-column: 1/3 3

  @media screen and (max-width: $screen-sm)
    lost-column: 1/2 2

  @media screen and (max-width: $screen-xs)
    lost-column: 1 0 0
    margin: 0 0 ($margin * 2)

  a
    box()

  @supports (display: flex)
    display: flex
    flex-direction: column
    justify-content: center




// ===== Other components =====

.column-container
  lost-center: $screen-lg

  .column
    lost-column: 1/2
    margin-bottom: $margin
    text-align: left

    @media screen and (max-width: $screen-md)
      lost-column: 1



.tab-selection
  li
    display: inline-block
    padding: $margin-sm
    font-size: ($font-scale-big)em
    text-shadow: 0 3px 3px $color-interaction

    .selected
      text-decoration: underline


.media-wrapper
  margin-bottom: $margin * 2
  position: relative
  padding-bottom: 56.25% /* Default for 1600x900 videos 16:9 ratio*/
  padding-top: 0px
  height: 0
  overflow: hidden

  iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
